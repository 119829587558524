import React from 'react';

function About() {
  return (
    <div className="container">
      	<div className="page-title">
	      	<p>About Me</p> 
	    </div>
	  	<div className="page-info" >
	  		<p>Professionally connected with the web development industry and 
	  		information technology for more than 3 years.</p>
	  		<p>Well-organised person, problem solver, 
	  		independent employee with high attention to detail. 
	  		Fan of UFC, football, chill mix and, recently, eth hack. 
	  		Like to work without interruptions, therefore remote employment is preferred.</p>
	  		<p>Interested in the entire frontend spectrum and working 
	  		on ambitious projects with positive people.</p>

	  		<h2>Education</h2>
	  		<ul>
		  		<li>Bachelors(hons) in Computer Science from London Metropolitan University - 2019.</li>
		  		<li>Higher Secondary School in Science from Sagarmatha College - 2016.</li>
		  		<li>Secondary School from Jyotikunj High School - 2014.</li>
	  		</ul>
	  	</div>
    </div>
  );
}

export default About;
