import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Header from './components/layouts/Header.js';
import Main from './components/Main.js';
import About from './components/pages/About.js';
import Skills from './components/pages/Skills.js';
import Work from './components/pages/Work.js';
import Contact from './components/pages/Contact.js';


function App() {
  return (
  	<Router>
	    <div className="App" style={{
	    		maxWidth: '100%', 
	    		overflow: 'auto', 
	    		backgroundRepeat: 'no-repeat',
    			backgroundSize: 'cover',
	    		backgroundImage: 'url("../bg_image.jpg")'
	    	}}>
	      <Header />
	      <Route exact path="/" component={Main} />
	      <Route path="/about" component={About} />
	      <Route path="/skills" component={Skills} />
	      <Route path="/work" component={Work} />
	      <Route path="/contact" component={Contact} />
	    </div>
	</Router>
  );
}

export default App;
