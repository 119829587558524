import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiHome, FiMail, FiUser, FiFile, FiCode } from 'react-icons/fi';
import { FaFacebook, FaTwitter, FaGithub, FaLinkedinIn } from 'react-icons/fa';



function Header() {
	useEffect(() => {
		const navLinks = document.querySelectorAll('.nav-link a');
		let path = window.location.pathname;

		navLinks.forEach((navlink, i, arr)=>{
			const icon = navlink.querySelector('svg'); // icon to add active class

			arr.forEach(a=>{ // give active class to relevant link on page load/reload
				if(a.getAttribute("href") === path) {
					a.querySelector('svg').classList.add('active');
				}
			});

			const toggleActive = () => {
				path = window.location.pathname // get url path

				arr.forEach(a=>{
					if(a.getAttribute("href") === path) {
						a.querySelector('svg').classList.remove('active');
					}
				}); // remove active class from previous link

				icon.classList.add('active'); // add active class to currently clicked link
			}

			navlink.addEventListener('click', toggleActive);
		})
	});
	return (
		<header>
			<nav className="navbar">
				<div style={{ backgroundColor: '#000', padding: '10px'}} className="nav-link">
					<Link to='/' >
						<svg style={{display: 'none'}}/>
						<p style={logo}>GM</p>
						<p style={{ color: '#DEDEDE', fontSize: '10px' }}>Umesh</p>
					</Link>
				</div>
				<div style={navLink} className="nav-link menu">
					<Link to='/' className="home"><FiHome/><span>Home</span></Link>
					<Link to='/about'><FiUser/><span>About</span></Link>
					<Link to='/skills'><FiCode/><span>Skills</span></Link>
					<Link to='/work'><FiFile/><span>Work</span></Link>
					<Link to='/contact'><FiMail/><span>Contact</span></Link>
				</div>
				<div style={navLink} className="social-link">
					<a href="https://github.com/cg99/" className="home"><FaGithub/></a>
					<a href="https://www.facebook.com/umessgm/"><FaFacebook/></a>
					<a href="https://twitter.com/umessgm"><FaTwitter/></a>
					<a href="https://www.linkedin.com/in/umesh-gm-677481137/"><FaLinkedinIn/></a>
				</div>
			</nav>
		</header>
	);
}

const logo = {
	fontSize: '24px', 
	fontWeight: '600', 
	color: '#00e8dd', 
    textShadow: '2px 2px 1px #e8009f'
}

const navLink={
	display: 'flex',
	padding: '0 10px 10px 10px',
}

export default Header;
