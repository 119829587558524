import React from 'react';

function Work() {
  return (
    <div className="container">
      	<div className="page-title">
	      	<p>My Works</p> 
	    </div>
	  	<div className="page-info" >
	  		<p>I have had a short but intense experience in the IT field. I started by making a small website for 
	  		a youth club in my hometown. Then created a local news site which I still manage. Then, I joined 
	  		Drishya, a software company building inhouse software for digital signage. 
	  		</p>
	  		<p>
	  		I also worked in a Computer Training Institute named Infomax as an instructor 
	  		to teach web development. Currently, I am working on some personal projects and 
	  		experimenting with differents tools and technologies and I am eager to apply them on real projects.
	  		</p>
	  		<h2>Experience</h2>
	  		<ul>
		  		<li>CTO - <a href="https://myagdinews.com">Myagdi News</a> / 2017-Present</li>
		  		<li>Front End Dev - <a href="http://drishya.com.np">Drishya</a> / 2018-19</li>
		  		<li>Instructor - <a href="https://www.facebook.com/Infomaxpvtltd/">Infomax</a> / 2019-20</li>
	  		</ul>
	  	</div>
    </div>
  );
}

export default Work;
