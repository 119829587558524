import React from 'react';
import {Link} from 'react-router-dom';

function Main() {
	const lit = (e) => {
		e.target.style.backgroundColor = '#00e8dd';
		e.target.style.color = '#1d1d1d';
	}
	const unlit = (e) => {
		e.target.style.backgroundColor = '#1d1d1d';
		e.target.style.color = '#00e8dd';
	}
	return (
	    <div className="container">
	    	
    		<div style={{fontSize: '52px', fontWeight: '600'}}>
		      	<p>Hi, </p> 
		      	<p>I'm Umesh, </p>
		      	<p>web developer. </p>
		    </div>
	      	<div style={tagline} >
	      		<p>Front End Developer / WordPress Theme Dev / Full Stack Web Dev</p>
	      	</div>
	      	<Link to="/contact" style={contactLink} onMouseEnter={lit} onMouseLeave={unlit}>CONTACT ME</Link>
    	
	    </div>
	);
}

const tagline = {
	margin: '8px 0 32px', 
	fontSize: '11px', 
	color: '#8d8d8d', 
	letterSpacing: '2.0px'
}
const contactLink = {
	textDecoration: 'none',
    border: '1px solid',
    color: '#00e8dd',
    padding: '10px',
    fontSize: '18px',
    letterSpacing: '2px'
}
export default Main;
