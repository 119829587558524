import React from 'react';

function Contact() {
  return (
    <div className="container">
      	<div className="page-title">
	      	<p>Contact Me</p> 
	    </div>
	  	<div className="page-info" >
	  		<p>I am interested in freelance opportunities – small or large projects. 
	  		However, if you have other request or question, don’t hesitate to contact me using below email either.</p>
	  		<p><span role="img" aria-label="email">📫 </span>umessgm@gmail.com</p>
	  		<p><span role="img" aria-label="phone">☎️ </span>(+977) 9806632775</p>
	  		<p><span role="img" aria-label="address">🗺️ </span>Pokhara, Nepal</p>
	  	</div>
	  	<div className="contact-btn" >
	  		<a href="mailto:umessgm@gmail.com?subject=Mail from Portfolio">Send Email</a>
	  	</div>
    </div>
  );
}

export default Contact;
