import React from 'react';
import {Link} from 'react-router-dom';

function Skills() {
  return (
    <div className="container">
      	<div className="page-title">
	   	   	<p>My Skills</p> 
	    </div>
	  	<div className="page-info" >
	  		<p>The main area of my expertise is front end development (client side of web).</p>
	  		<p>HTML5, CSS3, JS (ES6), single page applications with React JS, 
	  		features, transitions & animations, and coding interactive layouts.</p>
	  		<p>I have also full-stack developer experience with open source CMS WordPress and other 
	  		PHP framework like Codeigniter, CakePHP, Symfony and others.
	  		</p>
	  		
	  		<p>Visit my <a href="https://github.com/cg99/">Github</a> profile 
	  		for more details or just <Link to="/contact">contact</Link> me.</p>
	  	</div>
    </div>
  );
}

export default Skills;
